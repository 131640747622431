.pageNotFound {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

  .pageNotFound a:link {
    text-decoration: none;
    color: blue;
  }

  .pageNotFound a:visited {
    text-decoration: none;
    color: blue;
  }

  .pageNotFound a:hover {
    text-decoration: none;
    /* background-color: #121619; */
    color: orange;
    padding: 0px;
    border-radius: 5px;
  }
  
  .pageNotFound a:active {
    text-decoration: underline;
  }