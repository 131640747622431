.drawings {
    background-color: #f0d0d0;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 80%;
    position: relative;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .gallery-image {
    max-height: calc(80% - 40px);
    max-width: calc(100% - 40px);
    object-fit: contain;
    cursor: pointer;
    border: 10px solid brown;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .prev-button {
    position: 'absolute';
    left: '20px';
  }

  .nav-button {
    background: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .image-counter {
    margin-top: 20px;
    background-color: white;
    padding: 5px 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .zoomed .gallery-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: none;
    object-fit: contain;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    border: none;
    box-shadow: none;
  }
  
  .zoomed .image-container {
    background-color: transparent;
    box-shadow: none;
  }