.portfolio {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .portfolio .portfolioTitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 60px;
  }
  
  .portfolioList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  a {
    text-decoration: none;
    color: black;
  }

  /* MENU ITEM STYLING */
  
  .project {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  .project:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .project div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .project h1,
  .project p {
    margin-left: 20px;
  }
  
  @media only screen and (max-width: 1300px) {
    .project {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .project {
      grid-template-columns: 1fr;
    }
  }