.about {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .aboutTop {
    min-height: 30vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .aboutBottom {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    align-items: center;
    margin-top: 10px;
  }
  
  .aboutBottom p {
    width: 60%;
  }

  .aboutBottom h3 {
    width: 55%;
    margin: 0%;
    margin-top: 1%;
  }
  .aboutBottom h2 {
    width: 70%;
  }
  
  .aboutBottom a:link {
    text-decoration: none;
    color: blue;
  }

  .aboutBottom a:visited {
    text-decoration: none;
    color: blue;
  }

  .aboutBottom a:hover {
    text-decoration: none;
    /* background-color: #121619; */
    color: orange;
    padding: 0px;
    border-radius: 5px;
  }
  
  .aboutBottom a:active {
    text-decoration: underline;
  }

  .aboutBottom h1 {
    width: 80%;
    font-weight: 400;
    font-size: 60px;
    color: black;
    display: flex;
    justify-content: center;
    transform: translateY(-20px);
  }

  .aboutBottom h4 {
    font-size: 16px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }