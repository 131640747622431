.contact {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;  
  }
.contact h1 {
  max-width: fit-content;
  transform: translateX(80px);
}

.contact h3 {
  max-width: fit-content;
  transform: translateX(40px);
}