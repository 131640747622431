.navbar {
    width: 100%;
    height: 80px;
    background-color: #121619;
    display: flex;
    flex-direction: row;
  }
  
  .navbar .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .navbar .leftSide img {
    width: 60px;
  }
  
  .navbar .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  
  .navbar .rightSide a {
    display: block;
    color: white;
    text-decoration: none;
    margin: 0px;
    padding: 31px 20px;
  }
  
  .navbar .rightSide a:hover {
    background-color: #fc5841;
    color: #121619;
    
  }

  .navbar .leftSide a {
    display: block;
    color: white;
    text-decoration: none;
    margin: 0px;
    padding: 31px 20px;
  }

  .navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .navbar .rightSide svg {
    font-size: 40px;
  }


  @media only screen and (max-width: 600px) {
    .navbar #open a {
      display: none;
    }
  
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 0px;
    }
  
    .navbar #close button {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 601px) {
    .navbar .rightSide button {
      display: none;
    }

    .navbar .rightSide a {
      display: inherit;
    }
  }